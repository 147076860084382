<template>
  <v-card
    :outlined="outlined"
    :disabled="disabled"
    :color="color"
    :flat="flat"
    :loading="loading"
    :tile="tile"
    class="transparent"
    style="border: 1px solid #6B6B6B !important;"
  >
    <v-card-title
      class="py-1"
      style="font-size: 14px;"
    >
      {{ startsAtTimeSimple }}
      <v-chip
        v-if="booking.status === '0' || booking.status === false"
        x-small
        color="red"
        label
        class="ml-2"
        outlined
      >
        {{ $t('cancelled') }}
      </v-chip>
      <v-chip
        v-else-if="currentBookingState"
        x-small
        label
        class="ml-2"
        :color="currentBookingState.color"
        :dark="!currentBookingColorIsLight"
      >
        {{ currentBookingState.text }}
      </v-chip>
    </v-card-title>
    <v-card-text
      style="font-size: 12px;"
      class="pb-2"
    >
      <div
        id="event-info"
        class="pb-2"
      >
        <span>
          <span v-if="Array.isArray(booking.locationVariations) && booking.locationVariations.length > 0">
            {{ $tc('Table', booking.locationVariations.length) }}
            <span style="font-weight: 700;">
              {{ tables }}
            </span>
          </span>
          <span v-else-if="booking && booking.title">
            {{ booking.title }}
          </span>
          <span v-else-if="booking && booking.item && booking.item.title">
            {{ booking.item.title }}
          </span>
          {{ $t('for') }}
          <span style="font-weight: 700;">
            {{ booking.quantity }}
          </span>
          {{ $t('peopleLC') }}.
        </span>
      </div>

      <div v-if="booking.externalNote">
        <span>
          <span style="font-weight: 700;">
            {{ $t('noteFromCustomer') }}:
          </span>
          <span>
            {{ booking.externalNote }}
          </span>
        </span>
      </div>

      <div v-if="booking.internalNote">
        <span>
          <span style="font-weight: 700;">
            {{ $t('note') }}:
          </span>
          <span>
            {{ booking.internalNote }}
          </span>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
	name: "SimpleBookingCard",
	props: {
		booking: {
			type: Object,
			default: () => ({})
		},
		outlined: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: ''
		},
		flat: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		tile: {
			type: Boolean,
			default: false
		}
	},
	computed: {
    availableBookingStates() {
      return this.$store.state.availableBookingStates
    },
    currentBookingState() {
      if(typeof this.booking !== 'undefined' && this.booking !== null) {
        if(typeof this.booking.state !== 'undefined' && this.booking.state !== null) {
          if(Array.isArray(this.availableBookingStates) && this.availableBookingStates.length > 0) {
            return this.availableBookingStates.find(state => state && state.value === this.booking.state)
          }
        }
      }
      return null
    },
    currentBookingColorIsLight() {
      if(this.currentBookingState) {
        return this.$store.getters.colorIsLight(this.currentBookingState.color)
      }
      return false
    },
    bookingExists() {
			return typeof this.booking !== 'undefined' && this.booking !== null
		},
		tables() {
			if(this.bookingExists) {
				if(Array.isArray(this.booking.locationVariations) && this.booking.locationVariations.length > 0) {
					return this.booking.locationVariations.map(variation => variation.label).join(',')
				}
			}
			return ''
		},
		startsAtTimeSimple() {
			if(this.bookingExists) {
				if(typeof this.booking.startsAtTime !== 'undefined' && this.booking.startsAtTime !== null) {
					const timestampToDate = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(this.booking.startsAtTime))
					const date = timestampToDate.toLocaleDateString()
					const time = timestampToDate.toISOString().substring(11, 16)
					return date + ' ' + time
				}
			}
			return ''
		},
		startsAtTimeFancy() {
			return ''
		}
	}
}
</script>

<style scoped>

</style>
